import React from "react";
import mission from "../../assets/user/about/mission.jpeg";

const MissionVission = () => {
  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
      <div>
        <img src={mission} alt="Mission Image" className="rounded-xl" />
      </div>
      <div>
        <div>
          <h1 className="text-3xl font-medium">Our Mission</h1>
          <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white w-[30%] my-2"></div>
          <p className="mt-4 text-secondaryTextColor tracking-wide text-justify">
            At Techmentee Inc., our mission is to deliver innovative and
            reliable software solutions that empower businesses to thrive in the
            digital age. We are dedicated to providing top-notch website
            development, application development, and billing software that meet
            the unique needs of our clients.
          </p>
        </div>
        <div className="mt-8">
          <h1 className="text-3xl font-medium">Our Vision</h1>
          <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white w-[30%] my-2"></div>
          <p className="mt-4 text-secondaryTextColor tracking-wide text-justify">
            Our vision is to be a global leader in the software industry,
            recognized for our commitment to excellence, innovation, and
            customer satisfaction. We aim to foster lasting partnerships by
            continuously enhancing our solutions and adapting to the evolving
            technological landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MissionVission;

import React, { useState } from "react";
import ourProduct_bg from "../../assets/user/home/ourProduct_bg.svg";
import punching_img from "../../assets/user/home/punching_img.png";
import { MdOutlineCheck } from "react-icons/md";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
  motionVariantsCardFlip,
  motionVariantsRight,
  motionVariantsSlipRight,
  motionVariantsSlipTop,
} from "../common/animations";

const OurProducts = () => {
  const [tabValue, setTabValue] = useState("Hostel");

  return (
    <div className=" py-5 overflow-hidden">
      <motion.div
        variants={motionVariantsRight}
        {...motionProperties}
        className="tracking-wider flex flex-col gap-4 lg:px-16 md:px-10 px-5"
      >
        <p className="text-center text-primaryLightOrange">Our Products</p>
        <h1 className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor">
          Check Which Product We Create
        </h1>
        <p className="text-center m-auto text-sm lg:w-[80%] text-secondaryTextColor tracking-wider">
        Our hostel management system is designed to streamline the daily
        operations of hostels, making it easier for administrators to manage
        student accommodations, room allocations, billing, and more.
        With our attendance system, tracking student attendance has never been
        easier. Our system offers real-time attendance tracking, comprehensive
        reporting, and integration with existing student databases.
        Experience the efficiency and convenience of our systems tailored to
        meet the unique needs of educational institutions.
      </p>
      </motion.div>

      <motion.div
        variants={motionVariantsBounceIn}
        {...motionProperties}
        className="flex md:flex-row flex-col items-center justify-between mt-8 m-auto w-[70%]"
      >
        <button
          className={`${
            tabValue === "Hostel"
              ? "text-primaryLightOrange  border-primaryLightOrange"
              : "border-transparent"
          } border-b-2 cursor-pointer w-full pb-2 hover:text-primaryLightOrange`}
          onClick={() => setTabValue("Hostel")}
        >
          Hostel Management System{" "}
        </button>
        <button
          className={`${
            tabValue === "Attendance"
              ? "text-primaryLightOrange border-primaryLightOrange"
              : "border-transparent"
          } border-b-2 cursor-pointer w-full pb-2 hover:text-primaryLightOrange`}
          onClick={() => setTabValue("Attendance")}
        >
          Attendance tracking System
        </button>
      </motion.div>

      {tabValue === "Hostel" && (
        <div className="grid lg:grid-cols-2 grid-cols-1 mt-10 tracking-wider">
          <motion.div
            variants={motionVariantsBounceIn}
            {...motionProperties}
            className="h-auto"
          >
            <img src={ourProduct_bg} alt="our prod" className="w-full" />
          </motion.div>
          <motion.div
            variants={motionVariantsSlipTop}
            {...motionProperties}
            className="flex items-center lg:pr-16 md:p-10 p-5"
          >
            <div>
              <h1 className="text-2xl font-medium">Hostel Management System</h1>
              <p className="mt-4 text-secondaryTextColor tracking-wider leading-8">
                Our Hostel Management System provides a comprehensive solution
                for managing all aspects of hostel operations. From student
                accommodation to facilities management, our system offers the
                following key features:
              </p>
              <div className="flex flex-col gap-2 mt-4">
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Centralized student accommodation management.</p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Efficient room allocation and vacancy tracking.</p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Integrated billing and payment processing.</p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Attendance monitoring and reporting.</p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Visitor management and security features.</p>
                </div>
              </div>
              <button className="bg-gradient-to-r mt-4 from-[#7f9ac0] to-[#608ac4] hover:from-[#608ac4] hover:to-[#7f9ac0] transition duration-200 ease-linear text-white px-8 py-2 rounded">
                Know More
              </button>
            </div>
          </motion.div>
        </div>
      )}
      {tabValue === "Attendance" && (
        <div className="grid lg:grid-cols-2 grid-cols-1 mt-10 tracking-wider">
          <motion.div
            variants={motionVariantsBounceOut}
            {...motionProperties}
            className="h-auto"
          >
            <img src={punching_img} alt="our prod" className="w-full" />
          </motion.div>
          <motion.div
            variants={motionVariantsSlipTop}
            {...motionProperties}
            className="flex items-center lg:pr-16 md:p-10 p-5"
          >
            <div>
              <h1 className="text-2xl font-medium">
                Attendance Management System
              </h1>
              <p className="mt-4 text-secondaryTextColor tracking-wider leading-8">
                Our Attendance Management System simplifies the process of
                tracking and managing attendance for organizations. Key features
                include:
              </p>
              <div className="flex flex-col gap-2 mt-4">
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Automated attendance recording and monitoring.</p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>
                    Flexible attendance tracking options, including biometric
                    and RFID.
                  </p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>Customizable attendance reports and analytics.</p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>
                    Integration with payroll and HR systems for seamless
                    processing.
                  </p>
                </div>
                <div className="flex gap-2">
                  <MdOutlineCheck color="orange" className="mt-1" />
                  <p>
                    User-friendly interface for easy navigation and management.
                  </p>
                </div>
              </div>
              <button className="bg-gradient-to-r mt-4 from-[#7f9ac0] to-[#608ac4] hover:from-[#608ac4] hover:to-[#7f9ac0] transition duration-200 ease-linear text-white px-8 py-2 rounded">
                Know More
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default OurProducts;

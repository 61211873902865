import Login from "../screens/nonAuth/Login";
import Register from "../screens/nonAuth/Register";
import Dashboard from "../screens/auth/Dashboard";
import Home from "../screens/users/Home";
import Setting from "../screens/auth/Setting";
import ServiceDetails from "../screens/users/ServiceDetails";
import Services from "../screens/users/Services";
import AboutUs from "../screens/users/AboutUs";
import ContactUs from "../screens/users/CantactUs";

export const authRoutes = [
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
  {
    path: '/setting',
    component: <Setting />,
  }
];

export const nonAuthRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/register',
    component: <Register />,
  },
];

export const userRoutes = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: '/about-us',
    component: <AboutUs />,
  },
  {
    path: '/services',
    component: <Services />,
  },
  {
    path: '/contact-us',
    component: <ContactUs />,
  },
  {
    path: '/services/:id',
    component: <ServiceDetails />,
  },
];

import React, { useState } from "react";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      alert("Please fill out all fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:8000/webbocket/inquiry/create-inquiry",
        formData
      );
      setLoading(false);
      setShowModal(true);
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting inquiry:", error);
      setError("Error submitting inquiry. Please try again later.");
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="lg:px-16 md:px-10 px-5 py-10">
      <div className="relative w-full bg-white shadow-lg rounded-lg grid grid-cols-1 md:grid-cols-2 overflow-hidden">
      <div className="p-8 bg-[#7B96BD] text-white">
          <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
          <p className="mb-4">
          Techmentee is a leading provider of innovative IT solutions aimed at
            helping businesses thrive in the digital age.
          </p>
          <p className="mb-4">
            With a team of highly skilled professionals and a commitment to
            excellence, we deliver cutting-edge technology solutions tailored to
            the unique needs of our clients.
          </p>
          <p className="mb-4">
            Whether you have a question about our services, need assistance with
            a project, or simply want to learn more about how we can help your
            business succeed, we're here to help.
          </p>
          <p>
            Reach out to us through the contact form, and one of our
            representatives will get back to you as soon as possible.
          </p>
        </div>

        <div className="relative p-8 bg-white">
        <form
            className="space-y-6"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <h3 className="text-xl font-bold text-blue-700">
              Contact us
            </h3>

            <div className="relative">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="border w-full px-4 py-2 rounded-md focus:outline-none focus:border-blue-700"
              />
            </div>
            <div className="relative">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="border w-full px-4 py-2 rounded-md focus:outline-none focus:border-blue-700"
              />
            </div>
            <div className="relative">
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                className="border w-full px-4 py-2 rounded-md focus:outline-none focus:border-blue-700"
              />
            </div>
            <div className="relative">
              <textarea
                name="message"
                rows={4}
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                className="border w-full px-4 py-2 rounded-md focus:outline-none focus:border-blue-700"
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full p-3 bg-[#7B96BD] text-white font-bold rounded-md transition duration-300 hover:bg-[#6183b2]"
            >
              {loading ? (
                <svg
                  aria-hidden="true"
                  class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Send"
              )}
            </button>
          </form>
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <p className="text-2xl font-bold mb-4">Thank you for contacting us!</p>
            <p>We will get back to you as soon as possible.</p>
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-cyan-600 text-white rounded-md hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;

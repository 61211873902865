import React from "react";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceOut,
  motionVariantsFadeIn,
  motionVariantsLeft,
  motionVariantsScaleUp,
} from "../common/animations";

const teamMembers = [
    {
      name: "Peeyush Jena",
      position: "Founder and MD",
      image: "https://img.freepik.com/premium-photo/illustration-man-abstract-art-ai-generated_57312-1415.jpg",
      description: `"Peeyush Jena is a visionary leader with a passion for innovation and excellence. As the Founder and MD, he drives the company towards new heights with his strategic insights and dynamic approach. His expertise in the industry and commitment to delivering exceptional solutions have been instrumental in our success."`,
    },
    {
      name: "Ramesh.",
      position: "Founder and CTO",
      image: "https://img.freepik.com/premium-photo/illustration-man-abstract-art-ai-generated_57312-1415.jpg",
      description: `"Ramnesh, as the Founder and CTO, brings a wealth of technical knowledge and experience to our team. His dedication to cutting-edge technology and problem-solving capabilities ensures that we stay ahead of the curve. He is committed to creating innovative solutions that meet the evolving needs of our clients."`,
    },
  ];
  

const OurTeam = () => {
  return (
    // <div>
    //   <div className="tracking-wider">
    //     <motion.p
    //       variants={motionVariantsScaleUp}
    //       {...motionProperties}
    //       className="text-center text-primaryLightOrange"
    //     >
    //       Our Team
    //     </motion.p>
    //     <motion.h1
    //       variants={motionVariantsBounceOut}
    //       {...motionProperties}
    //       className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor"
    //     >
    //       Our Buddy's Always Ready To <br /> Solve Your Issues
    //     </motion.h1>
    //     <motion.p
    //       variants={motionVariantsLeft}
    //       {...motionProperties}
    //       className="text-center m-auto text-sm lg:w-[60%] text-secondaryTextColor leading-6"
    //     >
    //       At WebBocket, our team comprises talented professionals who are
    //       passionate about delivering exceptional results. With diverse skills
    //       and expertise, we work collaboratively to address your needs and
    //       exceed your expectations.
    //     </motion.p>
    //   </div>

    //   <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-8">
    //     {teamMembers.map((member, index) => (
    //       <div key={index} className="p-4 border rounded-xl shadow-orange-300 shadow-md">
    //         <div className="md:h-[400px] overflow-hidden rounded-lg">
    //           <img
    //             src={member.image}
    //             alt={member.name}
    //             className="w-full"
    //           />
    //         </div>
    //         <p className="mt-4 text-secondaryTextColor text-sm tracking-wider text-justify leading-6">
    //           {member.description}
    //         </p>
    //         <h1 className="font-segoe text-primaryLightOrange text-2xl my-2 text-center">
    //           {member.name}
    //         </h1>
    //         <h2 className="text-center text-secondaryTextColor text-sm">{member.position}</h2>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <></>
  );
};

export default OurTeam;

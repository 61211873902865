import React, { useState } from "react";
import core from "../../assets/user/home/core_features.svg";
import coreWhite from "../../assets/user/home/core_feature_white.svg";
import easyCos from "../../assets/user/home/easy_costomizable.svg";
import easyCosWhite from "../../assets/user/home/easy_costomizable_white.svg";
import support from "../../assets/user/home/fast_support.svg";
import supportWhite from "../../assets/user/home/fast_support_white.svg";
import { motion, AnimatePresence } from "framer-motion";
import {
  motionProperties,
  motionVariantsFadeIn,
  motionVariantsRight,
  motionVariantsScaleDown,
  motionVariantsScaleUp,
  motionVariantsSlideUpDown,
  motionVariantsSlipTop,
} from "../common/animations";

const data = [
  {
    name: "Core Features",
    desc: "Cutting-edge, custom software with user-centric design, scalability, security, and comprehensive support. Reliable and innovative solutions for business success.",
    image1: core,
    image2: coreWhite,
  },
  {
    name: "Easy Customizable",
    desc: "Flexible software solutions designed for easy customization, allowing seamless adjustments to meet specific business needs. User-friendly interfaces ensure effortless modifications and efficient scalability.",
    image1: easyCos,
    image2: easyCosWhite,
  },
  {
    name: "Fast Support",
    desc: "Rapid and reliable support services, ensuring quick resolutions and minimal downtime. Our dedicated team is available around the clock to address your needs promptly and efficiently.",
    image1: support,
    image2: supportWhite,
  },
];

const WhyChooseUs = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="lg:px-16 md:px-10 px-5 py-10 overflow-hidden">
      <div className="tracking-wider flex flex-col gap-4">
        <motion.p
          variants={motionVariantsScaleUp}
          {...motionProperties}
          className="text-center text-primaryLightOrange"
        >
          Why Choose Us
        </motion.p>
        <motion.h1
          variants={motionVariantsSlideUpDown}
          {...motionProperties}
          className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor font-dmSans font-medium tracking-wider"
        >
          Discover Effortless Customer Support
        </motion.h1>
        <motion.p
          variants={motionVariantsRight}
          {...motionProperties}
          className="text-center m-auto text-sm lg:w-[70%] text-secondaryTextColor"
        >
          This letter expresses our sincere gratitude for the excellent work
          that your company has done. I would like to note the high
          professionalism of the entire team, the ability to work with the
          customer, taking into account all his wishes.
        </motion.p>
      </div>

      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4  mt-8 tracking-wider">
        {data?.map((ele, index) => (
          <motion.div
            variants={
              index % 2 == 0 ? motionVariantsRight : motionVariantsSlipTop
            }
            {...motionProperties}
            key={index}
            className="h-auto text-center hover:bg-[#EFAE59] xl:p-10 p-5 duration-300 transition ease-linear rounded-md "
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img
              src={hoveredIndex === index ? ele.image2 : ele.image1}
              alt="core features image"
              className="m-auto w-14"
            />
            <h1
              className={`${
                hoveredIndex === index && "text-white"
              } text-center w-[90%] m-auto text-xl my-2`}
            >
              {ele.name}
            </h1>
            <p
              className={`${
                hoveredIndex === index && "text-white"
              } text-center w-[90%] m-auto text-secondaryTextColor`}
            >
              {ele.desc}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;

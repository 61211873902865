import React, { useEffect, useState } from 'react'
import Navbar from '../../components/common/Navbar'
import UserFooter from '../../components/common/UserFooter'
import ContactModal from '../../components/contac/ContackUsModal';
import inquiry from '../../assets/user/inquiry.svg'
import { useLocation } from 'react-router-dom';
const UserLayout = (props) => {
  const [showModal, setShowModal] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <Navbar />
      {props.children}


      <UserFooter/>
      <div onClick={handleShowModal} className='bg-primaryDarkOrange border-2 shadow-black shadow-sm w-16 h-16 p-2 rounded-full fixed right-4 bottom-4 flex items-center justify-center text-white text-lg cursor-pointer'>
        <img src={inquiry} alt="inquiry" className='w-full h-full'/>
      </div>
      <ContactModal show={showModal} handleClose={handleCloseModal} />
    </div>
  )
}

export default UserLayout
